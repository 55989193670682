import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ComponentsModule } from './components/components.module';

import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore'; // << Note AngularFirestoreModule !!!
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AuthService } from './services/auth.service';
import { B1xPlayerPageModule } from './pages/b1x-player/b1x-player.module';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { FirebaseService } from './firebase/firebase-integration.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';


import {
  AngularFireFunctionsModule
} from '@angular/fire/functions';


// import { IonicStorageModule, Storage } from '@ionic/storage'
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    ComponentsModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    B1xPlayerPageModule,
    QRCodeModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    /*IonicStorageModule.forRoot({
      name: 'B1X-db',
        driverOrder: ['indexeddb', 'websql', 'sqlite']
    }),*/
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    AngularFirestore, AuthService, FirebaseService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
