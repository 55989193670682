import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { B1xPlayerPageRoutingModule } from './b1x-player-routing.module';

import { B1xPlayerPage } from './b1x-player.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    B1xPlayerPageRoutingModule
  ],
  declarations: [B1xPlayerPage]
})
export class B1xPlayerPageModule {}
