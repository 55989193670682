import { Component, ViewChild } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, RouterEvent } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LoadingController, MenuController, ToastController } from '@ionic/angular';
import { FirebaseApp } from '@angular/fire';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MudConnectionService } from './mud-connection.service';
import { FirebaseService } from './firebase/firebase-integration.service';
import { CommonService } from './services/common.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {



  isLoggedIn:boolean;
  
  
  remoteConfig;
  currentVersion;
  isSuspended:boolean;
  accountsAmount:string
  
  firebaseAnalytics:any;

  textDir = 'ltr';
  myId;
  myUsername;
  loading;
  activePath:string;

  chosenLang:string;


  constructor(public loadingController: LoadingController, private commonServices:CommonService, private toastController:ToastController, private firebaseAnal:AngularFireAnalytics,  private firebase:FirebaseApp, private menu: MenuController, private auth:AuthService, public translate: TranslateService, private router:Router) {
    
    this.initializeApp();
    this.setLanguage();
    this.commonServices.init();
    this.presentLoading();
    this.firebaseAnalytics = this.firebase.analytics();

    this.remoteConfig = this.firebase.remoteConfig();
    
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 1800000,
    };

    
  
    this.remoteConfig.fetchAndActivate()
      .then(() => {
        

        this.currentVersion = this.remoteConfig.getString('currentVersion');
        this.isSuspended = this.remoteConfig.getBoolean('isSuspended');
        this.accountsAmount = this.remoteConfig.getString('accountsAmount');
        this.welcome();
        if(this.isSuspended)
        {
          alert("Demokracja Płynna has been suspended!!! Please wait and watch our social media channels for more info")
        }
        //console.log('FECZ ver/.isSuspended/acc num:', this.currentVersion, this.isSuspended, this.accountsAmount);
      })
      .catch((err) => {
        console.error(err);
      });
        
    this.firebaseAnal.logEvent('STARTER', {ok: true}).catch((error: any) => console.error(error));

    this.firebaseAnal.setCurrentScreen("LIMBO");

    //this.chosenLang = 
    
    
    

    
    this.router.events.subscribe((event: RouterEvent) => {
      this.activePath = event.url;
      
      //console.log('this.activePath', this.activePath, event.id);


      if(this.activePath==undefined && event.id==undefined) return;

      //console.log('this.activePath', this.activePath, event.id);
      this.firebaseAnal.logEvent('ROUTE', {url: event.url}).catch((error: any) => console.error(error));
      this.firebaseAnal.setCurrentScreen(event.url);

     

      if(AuthService.MY_ACCOUNT_ID!="")
      {
        //console.log('I am already logged')
        
        this.myId = AuthService.MY_ACCOUNT_ID;
        this.myUsername = AuthService.MY_ACCOUNT_NAME;
        this.isLoggedIn = true;
      }
      else
      {
        //console.log('logged out')
        
        this.isLoggedIn = false;
        
      }
    })
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.translate.instant('Jednia'),
      duration: 2500
    });
    await this.loading.present();

    
    
    await this.loading.onDidDismiss();
    this.commonServices.init2();
    //console.log('Loading dismissed!', role, data );
  }

  set(settingName,value){
    return localStorage.set(`setting:${ settingName }`,value);
  }
  async get(settingName){
    return await localStorage.get(`setting:${ settingName }`);
  }
  async remove(settingName){
    return await localStorage.remove(`setting:${ settingName }`);
  }
  clear() {
    localStorage.clear()
  }

  presentMudSheet()
  {
    
    this.router.navigate(["app/mud-screen"]).then( rut =>{

      console.log('mudddy rut', rut);

    }).catch( err=>{
      console.log('mudddy err');
    })
  }
 

  async welcome() {
    

    
    
    const toast = await this.toastController.create({
      message: "BBLD v: " + this.currentVersion + " People: " + this.accountsAmount,
      animated: true,
      duration: 4000,
      position: 'bottom',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
            // this.auth.isLoggedIn = false;
            
          }
        }
      ]
    });
    toast.present();
  }

  goToMap()
  {
    this.router.navigate(["maps"])
  }

  goToGLogin()
  {
    this.router.navigate(["auth/login"])
  }
  goToSignup()
  {

    this.router.navigate(["auth/signup"])
  }

  goToLedgers()
  {
    this.router.navigate(["getting-started"])
  }
  goToGettingS()
  {

    this.router.navigate(["walkthrough"])
  }

  goToappNoti()
  {
    this.router.navigate(["app/notifications"])
  }
  goToProfile()
  {
    this.router.navigate(["app/gate/user/"+AuthService.MY_ACCOUNT_ID])
  }
  goToWallet()
  {
    this.router.navigate(["app/wallet"])
  }

  goToappChannels()
  {
    this.router.navigate(["app/channels"])
  }
  goToappGate()
  {
    this.router.navigate(["app/gate"])

  }

  goToabout()
  {
    this.router.navigate(["contact-card"])

  }

  async initializeApp() {
    try {
     await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('pl-PL');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if(window.navigator.language=='pl-PL')
      {
        this.translate.use('pl-PL')
      }
      else
      {
        this.translate.use('pl-PL')
      }


      let defLang = localStorage.getItem('defLang');
      


      

      
      if(!defLang)
      {
        console.log('brak defLang')
      }
      else
      {
        console.log('jest! defLang')
        this.translate.use('pl-PL')
      }
    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });

    //console.log("LANG?", window.navigator.language);

    //this.menu.open('loggedin');
    //window.setTimeout(this.checkUser, 4000);
    //this.menu.enable(true, 'loggedin');
    
  }

  

}
