import { Injectable } from '@angular/core';
import { Domains, Tools } from '../models/post';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { FirebaseService } from '../firebase/firebase-integration.service';
import { AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Clipboard } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  static PRICE_XRP_USD = 0.22;
  kategorie = [];
  showFollowing = false;

  ktoZaprasza:string;

  medals = [];
  
  constructor(
    private router:Router,
    private firebaseService:FirebaseService,
    public alertController:AlertController,
    private toastController:ToastController,
    public translate: TranslateService) 
    { 
      
  }

  init2()
  {

    this.kategorie[0].translation = this.translate.instant("EDU_KAT")
    this.kategorie[1].translation = this.translate.instant("HYDEPARK_KAT")
    this.kategorie[2].translation = this.translate.instant("EXPERT_KAT")
    this.kategorie[3].translation = this.translate.instant("HEALTH_KAT")
    
    ////console.log(this.kategorie)
  }

  init()
  {

    let edu = {

      img: "assets/imgs/kategorie/edu.png",
      name: 'edu',
      translation: this.translate.instant("EDU_KAT"),
      results: 0
    }

    let hydepark = {
      img: "assets/imgs/kategorie/hydepark.png",
      name: 'hydepark',
      translation: this.translate.instant("HYDEPARK_KAT"),
      results: 0
    }

    let experts = {
      img: "assets/imgs/kategorie/expert.png",
      name: 'expert',
      translation: this.translate.instant("EXPERT_KAT"),
      results: 0
    }

    let medic = {
      img: "assets/imgs/kategorie/health.png",
      name: 'health',
      translation: this.translate.instant("HEALTH_KAT"),
      results: 0
    }
    this.kategorie = [];
    this.kategorie.push(edu);
    this.kategorie.push(hydepark);
    this.kategorie.push(experts);
    this.kategorie.push(medic);
    
    //console.log('SERVIS', this.kategorie);
    let medal_1 = {
      img: "assets/imgs/medal1.png",
      name: this.translate.instant("MEDAL1_NAME"),
      desc: this.translate.instant("MEDAL1_DESC"),
      show: true,
      uid: 1
    }

    let medal_2 = {
      img: "assets/imgs/medal2.png",
      name: this.translate.instant("MEDAL2_NAME"),
      desc: this.translate.instant("MEDAL2_DESC"),
      show: false,
      uid: 2
    }

    let medal_3 = {
      img: "assets/imgs/medal3.png",
      name: this.translate.instant("MEDAL3_NAME"),
      desc: this.translate.instant("MEDAL3_DESC"),
      show: false,
      uid: 3
    }

    let medal_4 = {
      img: "assets/imgs/medal4.png",
      name: this.translate.instant("MEDAL4_NAME"),
      desc: this.translate.instant("MEDAL4_DESC"),
      show: false,
      uid: 4
    }

    let medal_5 = {
      img: "assets/imgs/medal5.png",
      name: this.translate.instant("MEDAL5_NAME"),
      desc: this.translate.instant("MEDAL5_DESC"),
      show: false,
      uid: 5
    }

    let medal_6 = {
      img: "assets/imgs/medal6.png",
      name: this.translate.instant("MEDAL6_NAME"),
      desc: this.translate.instant("MEDAL6_DESC"),
      show: false,
      uid: 6
    }

    let medal_7= {
      img: "assets/imgs/medal7.png",
      name: this.translate.instant("MEDAL7_NAME"),
      desc: this.translate.instant("MEDAL7_DESC"),
      show: false,
      uid: 7
    }

    let medal_8 = {
      img: "assets/imgs/medal8.png",
      name: this.translate.instant("MEDAL8_NAME"),
      desc: this.translate.instant("MEDAL8_DESC"),
      show: false,
      uid: 8
    }
    this.medals.push(medal_1);
    this.medals.push(medal_2);
    this.medals.push(medal_3);
    this.medals.push(medal_4);
    this.medals.push(medal_5);
    this.medals.push(medal_6);
    this.medals.push(medal_7);
    this.medals.push(medal_8);

  }


  setInvite(fromUID)
  {
    this.ktoZaprasza = fromUID;

    this.router.navigate(['auth/signup'])
  }

  getPriceDolar(b1x:number)
  {
    let price = b1x/1000 * CommonService.PRICE_XRP_USD;

    return this.getRound(price)
  }



  async presentAlertCustom(headerr, txtTranslated) {
    

    const alert = await this.alertController.create({
      header: this.translate.instant(headerr),
      message: txtTranslated,
      buttons: [
        {
          text: this.translate.instant("OK"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }
      ]
    });

    await alert.present();
  }


  async presentAlertRegister(txtTranslated, czyZostawEkran:boolean=false) {
    

    const alert = await this.alertController.create({
      header: this.translate.instant("NEED_LOGIN"),
      message: txtTranslated,
      buttons: [
        {
          text: this.translate.instant("SIGN_UP_PAGE"),
          cssClass: 'secondary',
          handler: (blah) => {
            this.router.navigate(["auth/signup"])          }
        },
        {
          text: this.translate.instant("CANCEL"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            if(!czyZostawEkran)
            {
              this.router.navigate(["app/gate"])       
            } 
          }
        }
      ]
    });

    await alert.present();
  }
  
  async showCopied()
  {
    const toast = await this.toastController.create({
      message: this.translate.instant("LINK_COPIED"),
      animated: true,
      position: 'middle',
      duration: 5000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            ////console.log('Cancel clicked');
            // this.auth.isLoggedIn = false;
            
          }
        }
      ]
    });
    toast.present();
  }

  
  async presentAlertInvite(link) {
    return;
    
    const alert = await this.alertController.create({
      header: this.translate.instant("INVITE_LINK_TO_SHARE"),
      message: this.translate.instant("INVITE_LINK_DESC") + " + 500 B1X",
      buttons: [
        {
          text: this.translate.instant("COPY_LINK"),
          cssClass: 'secondary',
          handler: (blah) => {
            //this.router.navigate(["auth/signup"])     
            Clipboard.write({
              string: link
            });
            
            this.showCopied();
          }
        },
        {
          text: this.translate.instant("OPEN_URL"),
          cssClass: 'secondary',
          handler: (blah) => {
            Clipboard.write({
              string: link
            });

            (<any>window).open(link);
            
          }
        },
        {
          text: this.translate.instant("CANCEL"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            
          }
        }
      ]
    });

    await alert.present();
  }
  
  addToBookmark(uid) {
    //console.log('addToBookmark', uid);
    this.firebaseService.addToBookmark(uid);
  }

  removeFromBookmark(uid) {
    //console.log('removeFromBookmark', uid);
    this.firebaseService.removeFromBookmark(uid);
  }

  getToolTranslate(tool)
  {

    let zwrot;
    if(tool==Tools.EOD)
    {

      zwrot = this.translate.instant('NEW_B1X_INI_EOD')
    }
    else if(tool==Tools.BROADCAST)
    {

      zwrot = this.translate.instant('NEW_B1X_INI_CAST')
    }
    else if(tool==Tools.VOD)
    {

      zwrot = this.translate.instant('NEW_B1X_INI_VOD')
    }
    else if(tool==Tools.WWW)
    {

      zwrot = this.translate.instant('NEW_B1X_INI_WWW')
    }
    return zwrot;
  }


  getCategoryTranslate(category)
  {

    let zwrot;
    if(category==Domains.EDU)
    {

      zwrot = this.translate.instant('EDU_KAT')
    }
    else if(category==Domains.EXPERT)
    {

      zwrot = this.translate.instant('EXPERT_KAT')
    }
    else if(category==Domains.HYDEPARK)
    {

      zwrot = this.translate.instant('HYDEPARK_KAT')
    }
    else if(category==Domains.HEALTH)
    {

      zwrot = this.translate.instant('HEALTH_KAT')
    }

    return zwrot;
  }

  like(uid) {
    this.firebaseService.likePost(uid);
  }

  dislike(uid) {
    this.firebaseService.dislikePost(uid);
  }

  
  getMyChallengeName(rewardID)
  {
    let icoName;

    this.medals.forEach(medal => {
      if(rewardID==medal.uid)
      {
        icoName = medal.name;
      }
    });

    return icoName;
  }

  getMyChallengeDesc(rewardID)
  {
    let icoName;

    this.medals.forEach(medal => {
      if(rewardID==medal.uid)
      {
        icoName = medal.desc;
      }
    });

    return icoName;
  }


  getMyChallengeIco(rewardID)
  {
    let icoName;

    this.medals.forEach(medal => {
      if(rewardID==medal.uid)
      {
        icoName = medal.img;
      }
    });

    return icoName;
  }


  getMyToolIcoName(tool:Tools)
  {
    let icoName="EOD.gif";

    if(tool==Tools.EOD)
    {
      icoName = "EOD.gif"
    }
    else if(tool==Tools.BROADCAST)
    {
      icoName = "BROADCAST.gif"
    }
    else if(tool==Tools.VOD)
    {
      icoName = "VOD.gif"
    }
    else if(tool==Tools.WWW)
    {
      icoName = "WWW.gif"
    }
   
    ////console.log("KAT ICO", icoName);
    icoName = '/assets/imgs/kategorie/'+icoName;
    return icoName;
  }
  getDate(date) {
    if (date)
      return this.przetlumacz(moment(new Date(date.seconds * 1000)).fromNow());
    return this.przetlumacz(moment(new Date()).fromNow());
  }

  countEv(obj) {
    if (obj)
      return Object.keys(obj).length;
    return 0;
  }


  replaceAllPL(str,mapObj){
    var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

    return str.replace(re, function(matched){
        return mapObj[matched.toLowerCase()];
    });
}

  przetlumacz(dateStr)
  {
    let nowyStr;
    if(this.translate.getDefaultLang()=='pl-PL')
    {
      ////console.log('pl data', dateStr)
      var mapObj = {years:"lata", months:"miesiące", month:"miesiąc", few:"kilka", seconds:"sekund", days:"dni",ago:"temu",a: "", hours:"godzin", hour:"godzinę", minutes:"minut", minute:"minutę", day:"dzień"};

      nowyStr = this.replaceAllPL(dateStr, mapObj)
    }
    else
    {
      nowyStr = dateStr;
    }

    return nowyStr
  }

  getMyCatIcoName(kat:Domains)
  {
    let icoName="edu.png";

    if(kat==Domains.EDU)
    {
      icoName = "edu.png"
    }
    else if(kat==Domains.EXPERT)
    {
      icoName = "expert.png"
    }
    else if(kat==Domains.HYDEPARK)
    {
      icoName = "hydepark.png"
    }
    else if(kat==Domains.HEALTH)
    {
      icoName = "health.png"
    }
   
    ////console.log("KAT ICO", icoName);
    icoName = '/assets/imgs/kategorie/'+icoName;
    return icoName;
  }

  
  getAvRating(ini)
  {
    let suma = 0;
    let ileCom = ini.comments.length;

    if(ileCom==0) return 2.5

    for (let i = 0; i < ileCom; i++) {
      
      suma = suma + ini.comments[i].rating;
      ////console.log('rating', ini.comments[i].rating);
    }
    ////console.log('suma', suma);
    let avRating = suma / ini.comments.length;
    ////console.log('avRating', avRating);

    return avRating.toPrecision(3);
  }



  commentState(ini: any) {
    for (let index = 0; index < ini.comments.length; index++) {
      if(ini.comments[index].commentBy == AuthService.MY_ACCOUNT_ID)
      {
        return true;
      }
    }

    return false;
  }

  likeState(likes: any) {
    if (likes) {
      if (likes[AuthService.MY_ACCOUNT_ID])
        return true;
    }
    return false;
  }

  bookmarkState(bookmark: any) {
    if (bookmark) {
      if (bookmark[AuthService.MY_ACCOUNT_ID])
        return true;
    }
    return false;
  }

  getThisIniMedal(tool:string, cat:string)
  {
    let ktory = 0;// = Math.floor(Math.random()*this.medals.length);
    if(tool==Tools.EOD)
    {
      if(cat==Domains.EDU)
      {
        ktory = 0;
      }
      else if(cat==Domains.EXPERT)
      {
        ktory = 1;
      }
      else if(cat==Domains.HYDEPARK)
      {

        ktory = 2;
      }
      else if(cat==Domains.HEALTH)
      {

        ktory = 3;
      }
    }
    else if(tool==Tools.BROADCAST)
    {
      if(cat==Domains.EDU)
      {
        ktory = 7;
      }
      else if(cat==Domains.EXPERT)
      {
        ktory = 7;
      }
      else if(cat==Domains.HYDEPARK)
      {

        ktory = 7;
      }
      else if(cat==Domains.HEALTH)
      {

        ktory = 7;
      }
    }
    if(tool==Tools.VOD)
    {
      if(cat==Domains.EDU)
      {
        ktory = 4;
      }
      else if(cat==Domains.EXPERT)
      {
        ktory = 5;
      }
      else if(cat==Domains.HYDEPARK)
      {

        ktory = 6;
      }
      else if(cat==Domains.HEALTH)
      {

        ktory = 6;
      }
    }
    else
    {
      ktory = 0;
    }
    ////console.log('ktory medal', ktory, this.medals[ktory])
    return ktory
  }


  tipState(ini: any) {
    for (let index = 0; index < ini.comments.length; index++) {
      if(ini.comments[index].tip && ini.comments[index].commentBy == AuthService.MY_ACCOUNT_ID)
      {
        return true;
      }
    }

    return false;
  }

  countTips(ini) {
    
    let tipsCount = 0;
    for (let index = 0; index < ini.comments.length; index++) {
      if(ini.comments[index].tip)
      {
        tipsCount++;
      };
      ////console.log('comment', element)
    }

    return tipsCount;
  }

  getRound(kwota)
  {
    return kwota.toFixed(3);
    ////console.log('kwota', this.profile.avPricePerMin);
  }
}
