import { ShellModel } from '../../shell/data-store';

export class FirebaseSkillModel extends ShellModel {
  id: string;
  name: string;

  constructor() {
    super();
  }
}

export class FirebaseUserModel extends ShellModel {
  
  uid: string;
  name: string;
  username: string;
  description: string;
  avPricePerMin: number;
  phone?: number;
  lang?: string;
  age?: number;
  birthdate?: number; // timestamp
  skills: Array<any> = [];
  languages: Object;
  type: string;
  photoURL: string;
  following: Object;
  followers: Object;
  trustlines: Object;
  walletID: string;
  expertize: Object;
  creditibility: Object;
  status: string;
  www?: string;
  isAcceptingB1Xaudio: boolean;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  youtube?: string;
  github?: string;
  twitch?:string;
  collections?: Array<any>;
  about?: string;
  membership?: string;
  job?: string

  constructor() {
    super();
  }
}

export interface Wallet {
  walletOwnerID: string,
  b1x: number,
  prefferedPipeTAKE: string,
  prefferedPipePAY: string,

  tag: number,      
  phone?: string,
  xrpPubKey: string,
  email?: string,
  pin?: string,      
  ilpAddress?: string,

  country?: string,
  region?: string,
  lat?: string,
  long?: string,

  openPipes: Array<any>,
  revStreams: Array<any>,
  apps: Array<any>,
  trustLines: Array<any>
}

export class FirebaseCombinedUserModel extends FirebaseUserModel {
  skills: Array<FirebaseSkillModel> = [
    new FirebaseSkillModel(),
    new FirebaseSkillModel(),
    new FirebaseSkillModel()
  ];

  constructor() {
    super();
  }
}
