export interface AvailabilityData {

    //timezone: string,
    isOnDemand: boolean,
    timeDailyStarts: Date,
    timeDailyEnds: Date,
    timeAdditionalDailyStarts?: Date,
    timeAdditionalDailyEnds?: Date,
    isOnWeekends: boolean,
    isOnBankHolidays: boolean
}


export interface IniDataPayload{
 
    isEscrow:boolean,
    b1xFee:number,
    isDateNeeded:boolean,
    isWWW:boolean,
    isImageNeeded:boolean,
    isVideoNeeded:boolean,
    location: boolean,
    priceNeeded: boolean
  }
  
export interface Post {    
    createdAt: Date,
    userName: string,
    rewardID?: string,
    description: string,
    photoURL: string,
    wwwURL?: string,
    videoURL?: any,
    wwwLink?: string,
    postByID: string,
    user: string,
    username?: string,
    userPhoto?: string,
    likes?: Array<any>,
    comments?: Array<any>,
    location?: Object,
    postOwner?: string,
    postOwnerId?: string,
    uid?:  string,
    read?: Object,
    postName?: String,
    b1x_amount: number;
    endingDate?: Date;
    tool?: Tools;
    category?: Domains;
    availabilityData?: AvailabilityData;
    freeSeconds?: number;
    videoAuthor?:string;
    collections?:Object;
    views:number;
    tags: Array<any>;
    bookmark: Object;
    selectedLang: string;
}


export enum Tools {    
    ALL = "all",
    POST = "POST",
    VOD = "VOD",
    EOD = "EOD",
    BROADCAST = "BROADCAST",
    WWW = "WWW"
    //FUND = "FUND",
    //SPORT = "SPORT",
    //CHANNEL = "CHANNEL",
    //BLOCK_POST = "BLOCK_POST"
}

export enum Domains {    
    ALL = "all",
    EDU="edu",
    HYDEPARK="hydepark",
    EXPERT='expert',
    HEALTH='health'
}