import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

import { Observable, of, forkJoin, throwError, combineLatest } from 'rxjs';
import { map, concatMap, first, filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DataStore, ShellModel } from '../shell/data-store';

import { FirebaseListingItemModel } from './listing/firebase-listing.model';
import { FirebaseUserModel, FirebaseSkillModel, FirebaseCombinedUserModel } from './user/firebase-user.model';
import { UserImageModel } from './user/select-image/user-image.model';
import { Post } from '../models/post';
import { AuthService } from '../services/auth.service';
import { resolve } from 'dns';

@Injectable()
export class FirebaseService {
  // Listing Page
  private listingDataStore: DataStore<Array<FirebaseListingItemModel>>;
  // User Details Page
  private combinedUserDataStore: DataStore<FirebaseCombinedUserModel>;
  private relatedUsersDataStore: DataStore<Array<FirebaseListingItemModel>>;
  // Select User Image Modal
  private avatarsDataStore: DataStore<Array<UserImageModel>>;



  notifications: any;




  constructor(private afs: AngularFirestore) {

    this.notifications = {
      read: [],
      unread: []
    }

    //setTimeout(this.chekNoti, 3000);
  }



  async checkLastUBI(){

    let zwrot;

    await this.afs.collection('basicIncome', ref => ref
      .where('sourceUid', '==' , AuthService.MY_ACCOUNT_ID)
      .orderBy('createdAt', 'desc').limit(1)).valueChanges()
      .subscribe((data:any)=>{

        

        console.log('basicIncome data', data[0]);


        zwrot =  data
      })

      return zwrot;
  }


  chekNoti()
  {
    
    

    this.afs.collection('notifications', ref => ref
      .where('destination', '==' , AuthService.MY_ACCOUNT_ID)
      .where('read', '==' , false)
      .orderBy('createdAt', 'desc').limit(50)).valueChanges()
      .subscribe((data:any)=>{

        this.notifications.unread = [];

        //console.log('chekNoti data', data);

        if (data.length > 0) {
          
          data.forEach(element => {

           
              this.notifications.unread.push(element);
            
            });
          }
      })





      this.afs.collection('notifications', ref => ref.where('destination', '==' , AuthService.MY_ACCOUNT_ID)
      .where('read', '==' , true)
      .orderBy('createdAt', 'desc').limit(50)).valueChanges()
      .subscribe((data2:any)=>{

        this.notifications.read = [];

        //console.log('chekNoti data2', data2);

        if (data2.length > 0) {
          
          data2.forEach(element => {

           
              this.notifications.read.push(element);
            
            });
          }
      })
  }

  /*
    Firebase User Listing Page
  */
  public getListingDataSource(): Observable<Array<FirebaseListingItemModel>> {
    return this.afs.collection<FirebaseListingItemModel>('users').valueChanges({ idField: 'id' })
     .pipe(
       map(actions => actions.map(user => {
          const age = this.calcUserAge(user.birthdate);
          return { age, ...user } as FirebaseListingItemModel;
        })
      )
    );
  }



  async readNotification(notiId) {
    //console.log('readNotification', notiId);

    await this.afs.doc('notifications/' + notiId).update({ read: true });

  }

  readAllLocations() {
    //const userId = AuthService.MY_ACCOUNT_ID;
    console.log('readAllLocations LOCA');
    
    return this.afs.collection('locations', ref => ref.limit(50)).snapshotChanges();
  }


  async addMyLocation(locationPin, ktoID) {
    //const userId = AuthService.MY_ACCOUNT_ID;
    console.log('pusheding LOCA', locationPin);
    
    //let randomID =  ktoID;//Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    locationPin.uid = ktoID;
    //locationPin.

    await this.afs.collection("locations").doc(ktoID).set(locationPin).then(()=>
    {
      console.log('pushed LOCA', locationPin)
    })
  }

  
  async commentPost(postId, obj) {
    //const userId = AuthService.MY_ACCOUNT_ID;

    let comments: any;
    ////console.log('addComment userId!', userId);



    comments = await new Promise((resolve) => {
      this.afs.doc('posts/' + postId).valueChanges()
        .subscribe((post: any) => {
          let comments: any;
          if (post)
            comments = post.comments;
            const comment = {
              user: obj.user,
              photoURL: obj.photoURL ? obj.photoURL : null,
              createdAt: obj.createdAt,
              commentBy: obj.commentBy,
              comment: obj.comment,
              tip: obj.tip ? obj.tip : null,
              rating: obj.rating
            };
            


          if (comments) {
            comments.push(comment);
          } else {
            comments = [comment];
          }
          resolve(comments);
        })
    });

    await this.afs.doc('posts/' + postId).update({ comments: comments });

  }

  //Notifications
  
  async createNotification(postId, event, username, userPhoto, authorID, text, listeners, rating, postName, tipAmount) {
    
    const currentUser = AuthService.MY_ACCOUNT_ID;
    

    for (let index = 0; index < listeners.length; index++) {

      const listener = listeners[index];
      //console.log('destination', listener);
      const notification = {
        event: event,
        destination: listener,
        authorName: username,
        authorPhoto: userPhoto,
        authorID: authorID,
        postId: postId,
        postName: postName,
        createdAt: Date.now(),
        text: text,
        rating: rating,
        read: false,
        tipAmount: tipAmount,
        uid : ""
      }

      let randomID =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      notification.uid = randomID
  
      await this.afs.collection("notifications").doc(randomID).set(notification).then(()=>
      {
        //console.log('pushed noti', notification)
      })

      //console.log('pushed noti OK')
    }
    
  }







async follow(userId) {
  const currentUser = AuthService.MY_ACCOUNT_ID;
  let following: any;
  following = await new Promise((resolve) => {
    this.afs.doc('accounts/' + currentUser).valueChanges()
      .subscribe((user: any) => {
        let following = user.following;
        following = { [userId]: true, ...following };

        resolve(following);
      })
  });
  ////console.log(following);

  this.afs.doc('accounts/' + currentUser).update({ following: following });

  let followers: any;
  followers = await new Promise((resolve) => {
    this.afs.doc('accounts/' + userId).valueChanges()
      .subscribe((user: any) => {
        let followers = user.followers;
        followers = { [currentUser]: true, ...followers };

        resolve(followers);
      })
  });

  this.afs.doc('accounts/' + userId).update({ followers: followers });

}








async unfollow(userId) {
  const currentUser = AuthService.MY_ACCOUNT_ID;
  let following: any;
  following = await new Promise((resolve) => {
    this.afs.doc('accounts/' + currentUser).valueChanges()
      .subscribe((user: any) => {
        let following = user.following;
        delete following[userId];

        resolve(following);
      })
  });
  this.afs.doc('accounts/' + currentUser).update({ following: following });
  let followers: any;
  followers = await new Promise((resolve) => {
    this.afs.doc('accounts/' + userId).valueChanges()
      .subscribe((user: any) => {
        let followers = user.followers;
        delete followers[currentUser];

        resolve(followers);
      })
  });

  this.afs.doc('accounts/' + userId).update({ followers: followers });
}


deletePost(uid){
  this.afs.collection('posts').doc(uid).delete()
              .then( () =>{
                alert('post deleted ');
              })
              .catch(err => console.log(err));
}

  public getListingStore(dataSource: Observable<Array<FirebaseListingItemModel>>): DataStore<Array<FirebaseListingItemModel>> {
    // Use cache if available
    if (!this.listingDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: Array<FirebaseListingItemModel> = [
        new FirebaseListingItemModel(),
        new FirebaseListingItemModel(),
        new FirebaseListingItemModel(),
        new FirebaseListingItemModel(),
        new FirebaseListingItemModel(),
        new FirebaseListingItemModel()
      ];

      this.listingDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.listingDataStore.load(dataSource);
    }
    return this.listingDataStore;
  }

  // Filter users by age
  public searchUsersByAge(lower: number, upper: number): Observable<Array<FirebaseListingItemModel>> {
    // we save the dateOfBirth in our DB so we need to calc the min and max dates valid for this query
    const minDate = (dayjs(Date.now()).subtract(upper, 'year')).unix();
    const maxDate =  (dayjs(Date.now()).subtract(lower, 'year')).unix();

    const listingCollection = this.afs.collection<FirebaseListingItemModel>('users', ref =>
      ref.orderBy('birthdate').startAt(minDate).endAt(maxDate));

    return listingCollection.valueChanges({ idField: 'id' }).pipe(
      map(actions => actions.map(user => {
         const age = this.calcUserAge(user.birthdate);
         return { age, ...user } as FirebaseListingItemModel;
       })
     ));
  }

  /*
    Firebase User Details Page
  */
  // Concat the userData with the details of the userSkills (from the skills collection)
  public getCombinedUserDataSource(userId: string): Observable<FirebaseCombinedUserModel> {
    return this.getUser(userId)
    .pipe(
      // Transformation operator: Map each source value (user) to an Observable (combineDataSources | throwError) which
      // is merged in the output Observable
      concatMap(user => {
        if (user && user.skills) {
          // Map each skill id and get the skill data as an Observable
          const userSkillsObservables: Array<Observable<FirebaseSkillModel>> = user.skills.map(skill => {
            return this.getSkill(skill).pipe(first());
          });

          // Combination operator: Take the most recent value from both input sources (of(user) & forkJoin(userSkillsObservables)),
          // and transform those emitted values into one value ([userDetails, userSkills])
          return combineLatest([
            of(user),
            forkJoin(userSkillsObservables)
          ]).pipe(
            map(([userDetails, userSkills]: [FirebaseUserModel, Array<FirebaseSkillModel>]) => {
              // Spread operator (see: https://dev.to/napoleon039/how-to-use-the-spread-and-rest-operator-4jbb)
              return {
                ...userDetails,
                skills: userSkills
              } as FirebaseCombinedUserModel;
            })
          );
        } else {
          // Throw error
          return throwError('User does not have any skills.');
        }
      })
    );
  }

  getUserPosts(userId) {
    return this.afs.collection('posts', ref => ref.where('postByID', '==', userId).orderBy('createdAt', 'desc')).snapshotChanges();
  }

  getAllPostsByLang(lang) {
    return this.afs.collection('posts', ref => ref.where('selectedLang', '==', lang).orderBy('createdAt', 'desc').limit(100)).snapshotChanges();
  }


  getAllUsers() {
    return this.afs.collection('accounts', ref => ref.orderBy('avPricePerMin', 'desc').limit(50)).snapshotChanges();
  }


  getAllPosts() {
    return this.afs.collection('posts', ref => ref.orderBy('createdAt', 'desc').limit(100)).snapshotChanges();
  }


  
  getPost(postId) {
    return this.afs.collection('posts').doc(postId).valueChanges();
  }


  async addToBookmark(postId) {
    const userId = AuthService.MY_ACCOUNT_ID;
    let bookmark: any;
    bookmark = await new Promise((resolve) => {
      this.afs.doc('posts/' + postId).valueChanges()
        .subscribe((post: any) => {
          let bookmark = post.bookmark;
          bookmark = { [userId]: true, ...bookmark };
          resolve(bookmark);
        })
    });

    this.afs.doc('posts/' + postId).update({ bookmark: bookmark });
  }

  async removeFromBookmark(postId) {
    const userId = AuthService.MY_ACCOUNT_ID;
    let bookmark: any;
    bookmark = await new Promise((resolve) => {
      this.afs.doc('posts/' + postId).valueChanges()
        .subscribe((post: any) => {
          let bookmark = post.bookmark;

          delete bookmark[userId];
          resolve(bookmark);
        })
    });

    this.afs.doc('posts/' + postId).update({ bookmark: bookmark });
  }


  async dislikePost(postId) {
    const userId = AuthService.MY_ACCOUNT_ID;
    let likes: any;
    likes = await new Promise((resolve) => {
      this.afs.doc('posts/' + postId).valueChanges()
        .subscribe((post: any) => {
          let likes = post.likes;
          delete likes[userId];

          resolve(likes);
        })
    });

    this.afs.doc('posts/' + postId).update({ "likes": likes });
  }

  async likePost(postId) {
    const userId = AuthService.MY_ACCOUNT_ID;
    let likes: any;
    likes = await new Promise((resolve) => {
      this.afs.doc('posts/' + postId).valueChanges()
        .subscribe((post: any) => {
          let likes = post.likes;
          likes = { [userId]: true, ...likes };

          resolve(likes);
        })
    });

    await this.afs.doc('posts/' + postId).update({ "likes": likes });
    //this.createNotification(postId, 'liked');
  }


  public getCombinedUserStore(dataSource: Observable<FirebaseCombinedUserModel>): DataStore<FirebaseCombinedUserModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: FirebaseCombinedUserModel = new FirebaseCombinedUserModel();

    this.combinedUserDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.combinedUserDataStore.load(dataSource);

    return this.combinedUserDataStore;
  }

  // tslint:disable-next-line:max-line-length
  public getRelatedUsersDataSource(combinedUserDataSource: Observable<FirebaseCombinedUserModel & ShellModel>): Observable<Array<FirebaseListingItemModel>>  {
    return combinedUserDataSource
    .pipe(
      // Filter user values that are not shells. We need to add this filter if using the combinedUserDataStore timeline
      filter(user => !user.isShell),
      concatMap(user => {
        if (user && user.skills) {
          // Get all users with at least 1 skill in common
          const relatedUsersObservable: Observable<Array<FirebaseListingItemModel>> =
          this.getUsersWithSameSkill(user.uid, user.skills);

          return relatedUsersObservable;
        } else {
          // Throw error
          return throwError('Could not get related user');
        }
      })
    );
  }



  getCollectionsByID(collID) {
    return this.afs.collection('postsCollections').doc(collID).valueChanges();
    //return this.afs.collection('postsCollections', ref => ref.where('items.' + postID, '==', true)).snapshotChanges();
  }


  
  public getRelatedUsersStore(dataSource: Observable<Array<FirebaseListingItemModel>>): DataStore<Array<FirebaseListingItemModel>> {
    // Initialize the model specifying that it is a shell model
    const shellModel: Array<FirebaseListingItemModel> = [
      new FirebaseListingItemModel(),
      new FirebaseListingItemModel(),
      new FirebaseListingItemModel()
    ];

    this.relatedUsersDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.relatedUsersDataStore.load(dataSource);

    return this.relatedUsersDataStore;
  }

  /*
    Firebase Create User Modal
  */
  public createUser(userData: FirebaseUserModel): Promise<DocumentReference>  {
    return this.afs.collection('accounts').add({...userData});
  }

  public shouldEmbedVODOnHelpCode(uid: string, userID, youtubeEmbedded): Promise<DocumentReference>  {

    let ytData = {

      initiativeID: uid,
      accountID: userID,
      ytLink: youtubeEmbedded
    }
    return this.afs.collection('videosFromYouTube').add({...ytData});
  }

  
  public addPost(postData: Post): Promise<DocumentReference>  {
    return this.afs.collection('posts').add({...postData});
  }

  
  /*
    Firebase Update User Modal
  */
  public updateUser(uid:string, userData: FirebaseUserModel): Promise<void> {
    ////console.log('userData', uid, userData);

    return this.afs.collection('accounts').doc(uid).set(userData);
  }

  public deleteUser(userKey: string): Promise<void> {
    return this.afs.collection('accounts').doc(userKey).delete();
  }

  /*
    Firebase Select User Image Modal
  */
  public getAvatarsDataSource(): Observable<Array<UserImageModel>> {
    return this.afs.collection<UserImageModel>('avatars').valueChanges();
  }

  public getAvatarsStore(dataSource: Observable<Array<UserImageModel>>): DataStore<Array<UserImageModel>> {
    // Use cache if available
    if (!this.avatarsDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: Array<UserImageModel> = [
        new UserImageModel(),
        new UserImageModel(),
        new UserImageModel(),
        new UserImageModel(),
        new UserImageModel()
      ];

      this.avatarsDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.avatarsDataStore.load(dataSource);
    }
    return this.avatarsDataStore;
  }

  /*
    FireStore utility methods
  */
  // Get list of all available Skills (used in the create and update modals)
  public getSkills(): Observable<Array<FirebaseSkillModel>> {
    //return this.afs.collection<FirebaseSkillModel>('skills').valueChanges({ idField: 'skillID' });
    return this.afs.collection<FirebaseSkillModel>('skills').valueChanges();
  }

  // Get data of a specific Skill
  private getSkill(skillId: string): Observable<FirebaseSkillModel> {
    return this.afs.doc<FirebaseSkillModel>('skills/' + skillId)
    .snapshotChanges()
    .pipe(
      map(a => {
        const data = a.payload.data();
        const id = a.payload.id;
        return { id, ...data } as FirebaseSkillModel;
      })
    );
  }


  // Get data of a specific User
  private getUser(userId: string): Observable<FirebaseUserModel> {
    return this.afs.doc<FirebaseUserModel>('accounts/' + userId)
    .snapshotChanges()
    .pipe(
      map(a => {
        const userData = a.payload.data();
        const uid = a.payload.id;
        const age = userData ? this.calcUserAge(userData.birthdate) : 0;
        return { uid, age, ...userData } as FirebaseUserModel;
      })
    );
  }

  // Get all users who share at least 1 skill of the user's 'skills' list
  private getUsersWithSameSkill(userId: string, skills: Array<FirebaseSkillModel>): Observable<Array<FirebaseListingItemModel>> {
    // Get the users who have at least 1 skill in common
    // Because firestore doesn't have a logical 'OR' operator we need to create multiple queries, one for each skill from the 'skills' list
    const queries = skills.map(skill => {
      return this.afs.collection('accounts', ref => ref
      .where('skills', 'array-contains', skill.id))
      .valueChanges({ idField: 'id' });
    });

    // Combine all these queries
    return combineLatest(queries).pipe(
      map((relatedUsers: FirebaseListingItemModel[][]) => {
        // Flatten the array of arrays of FirebaseListingItemModel
        const flattenedRelatedUsers = ([] as FirebaseListingItemModel[]).concat(...relatedUsers);

        // Removes duplicates from the array of FirebaseListingItemModel objects.
        // Also remove the original user (userId)
        const filteredRelatedUsers = flattenedRelatedUsers
        .reduce((accumulatedUsers, user) => {
          if ((accumulatedUsers.findIndex(accumulatedUser => accumulatedUser.id === user.id) < 0) && (user.id !== userId)) {
            return [...accumulatedUsers, user];
          } else {
            // If the user doesn't pass the test, then don't add it to the filtered users array
            return accumulatedUsers;
          }
        }, ([] as FirebaseListingItemModel[]));

        return filteredRelatedUsers;
      })
    );
  }

  private calcUserAge(dateOfBirth: number): number {
    return dayjs(Date.now()).diff(dayjs.unix(dateOfBirth), 'year');
  }
}
