import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseUserModel } from '../firebase/user/firebase-user.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { LoadingController, Platform } from '@ionic/angular';
import { resolve } from 'dns';
import { Ankieta } from '../models/AnkietaStartowa';
import { MudConnectionService } from '../mud-connection.service';
import { FirebaseService } from '../firebase/firebase-integration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static HELPCORE_HOT_WALLET:string = 'ro8Yp8uWQHsWqGqDBkZsrmCr2E12SPwuY';//"rHELP3yUE2jkxWS4EUJPgKGN674tBeKiGp";
  static HELPCORE_GATE:string = "TEJyKLvnz6Yb9YhUufrGKMiolKE2";
  static MY_ACCOUNT_ID:string = "";
  static MY_ACCOUNT_NAME:string = ""
  static MY_XRP_ADDRESS:string = "";


  public oobCode:string;

  public isLoggedIn = false;
  public ankietaStartowa:Ankieta;

  
  account:any;
  userData: Observable<firebase.User>;
  loading:any;
  eodData:any;

  constructor( private firebaseService:FirebaseService,    private platform:Platform, public loadingController: LoadingController, private router:Router, private afs: AngularFirestore, public afa: AngularFireAuth) {

    this.userData = afa.authState;

    //console.log("userData", this.userData);

    //try {
      // synchronous operation
      //const httpResponse =  getHttpResponseSync('/api/courses');
    //  }
    //catch(error) {
          // handle error
    //}

    
    this.afa.authState.subscribe(user => {

      if (user)
      {
        
        this.account = user;

        AuthService.MY_ACCOUNT_ID = this.account.uid;

        console.log("JEST KOLO", AuthService.MY_ACCOUNT_ID, this.router.url);

        this.firebaseService.chekNoti();

        
        if(this.ankietaStartowa)
        {
          this.zapiszAnkiete(AuthService.MY_ACCOUNT_ID, this.ankietaStartowa).then((ank)=>{
            //console.log("ankieta zapisana", ank);

            let klucz = AuthService.MY_ACCOUNT_ID;

            var listeners = []
            listeners.push(klucz);

            let objStr = 'Twoja ankieta startowa została zapisana, za co bardzo dziękujemy. Bardzo dziękujemy i serdecznie witamy na platformie HelpCore.'
            //console.log("createNotification objStr", objStr) 
              
            //console.log("createNotification zapisana listeners", listeners);

            this.firebaseService.createNotification('ankieta', 'FORM', 'MYSELF', '/assets/icon/icon-180x180.png', AuthService.MY_ACCOUNT_ID, objStr, listeners, 0, 'Ankieta startowa', 0);
          })
        }

        this.isLoggedIn = true;
        
        const { uid, email } = user;


        if(!user.email)
        {
          console.log("BRAK @");
          //this.account
        }

        if (user.emailVerified || (!user.emailVerified && user.phoneNumber)) {
          console.log("JEST user.emailVerified", user.emailVerified);
        } 

        if(user.isAnonymous === true) {
          console.log("JEST isAnonymous");
          
        }


        AuthService.MY_ACCOUNT_NAME = '...';

        if(this.router.url == '/walkthrough')
        {
          //this.router.navigate(['app/notifications'])
          this.router.navigate(['app/gate'])
        } 
        else if(this.router.url == '/auth/login')
        {
          //this.router.navigate(['app/notifications'])
          this.router.navigate(['app/gate'])
        }
        else if(this.router.url == '/auth/signup')
        {
          //this.router.navigate(['app/notifications'])
          this.router.navigate(['app/gate'])
        }
        else if(this.router.url == '/app/channels')
        {
          //this.router.navigate(['app/notifications'])
          this.router.navigate(['app/gate'])
        }
        else if(this.router.url == '/maps')
        {
          console.log('mapka?')
          this.router.navigate(['app/gate'])
        }
        else if(this.router.url == '/app/gate')
        {
          //this.router.navigate(['app/notifications'])
          this.router.navigate(['app/gate/user/'+AuthService.MY_ACCOUNT_ID])
        }
        else if(this.router.url == '/app/gate/user/'+AuthService.MY_ACCOUNT_ID)
        {
          this.router.navigate(['app/gate'])
        }
        else if(this.router.url == '/app/wallet')
        {
          console.log("wallie")
          this.router.navigate(['app/gate'])
        }

        
        
      } else {
        console.log("BRAK KOLO", this.router.url)
        
        this.isLoggedIn = false;
        AuthService.MY_ACCOUNT_ID="";

        let mode = this.platform.getQueryParam("mode");
        this.oobCode = this.platform.getQueryParam("oobCode");

        console.log("mode", mode);

        if(mode=='resetPassword')
        {
          console.log('resetingPass oobCode', this.oobCode);
          this.router.navigate(['auth/new-password/']);
        }
      }
    
    });
  }

  getAuth() { 
    return firebase.auth(); 
  } 

  sendPasswordReset(email)
  {
    return this.afa.sendPasswordResetEmail(email);
  }
  
  async zapiszAnkiete(id:string, ankieta:any) {

    console.log("zapiszAnkiete", ankieta);

    await this.afs.collection('questionarees')
      .add( {ankieta:ankieta, uid:id, createdDate:new Date()}).then(res =>{

        this.ankietaStartowa = null;
        return res;
      })
    
  }


  getWallet(walletId) {
    // console.log('walletId MY_XRP_SECRET', walletId, AccountProvider.MY_XRP_SECRET);

    return this.afs.collection('wallets').doc(walletId).valueChanges();
  }
  
  getAccount(userId) {
    //console.log('TAKE userId', userId);

    
      const currentUser = userId ? userId : AuthService.MY_ACCOUNT_ID;
      
      
      if(!currentUser || currentUser=='undefined' || currentUser=='') 
      {
        console.log('NO currentUser');
        return null;
      }
      //console.log("ostatecznie? currentUser", currentUser);
      const doc: any = this.afs.doc('accounts/' + currentUser);
      console.log('kogo doc', userId, currentUser, doc);

      if(!doc) return {null:true};

      if(AuthService.MY_ACCOUNT_ID==userId)
      {
        if(doc.username) AuthService.MY_ACCOUNT_NAME = doc.username;
        else AuthService.MY_ACCOUNT_NAME = 'PROFILE';
      }

      return this.account = doc.valueChanges();
    
  }

  

  signUpWithEmailAndPassword(obj)
  {
    console.log('signUpWithEmailAndPassword', obj);


    this.afa.createUserWithEmailAndPassword(obj.email, obj.password)
      .then(async(res) => {
        const uid:any = res;
        const email = uid.email;

        let user = firebase.auth().currentUser;
        user.sendEmailVerification();
          
        console.log('USER uid', uid);
        console.log('user', user);
      })
      .catch(e =>{
        console.log('signup er', e)
      });
  }


  signInAnonymously(): Promise<any> {
    return this.afa.signInAnonymously();
  }
  
}
