import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Post } from '../../models/post';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {

  @Input() timeline: any[];
  //@ViewChild('video') video: any;


  account: any;
  //actionSheet:ActionSheet;

  public static CURRENT_COLLECTION:string = "";

  constructor(private auth:AuthService) {
    
   }

  ngOnInit() {}


  getMyCatIcoName(kat:any)
  {
    let icoName="EOD_ico.png";

    
    if(kat=='EOD')
    {
      icoName = "EOD_ico.png"
    }
    else if(kat=='CAST')
    {
      icoName = "BROADCAST_ico.png"
    }
    else if(kat=='VOD')
    {
      icoName = "VOD_ico.png"
    }
   
    //console.log("KAT ICO", icoName);
    icoName = '/assets/imgs/'+icoName;
    return icoName;
  }


  
  openCollections(post:Post)
  {
    console.log("openCollections", post.collections);
    
    //this.navCtrl.push('CollectionViewPage', { post: post, collections : post.collections});
  }

  openTipownica(post:Post)
  {
    console.log("tipuj post", post);

    //this.modalCtrl.create('TipujPostaPage', { post: post, tipowicz: this.account}, {cssClass:"mymodal"}).present();
  }

  like(uid) {
    //this.timelineProvider.likePost(uid);
  }

  dislike(uid) {
    //this.timelineProvider.dislikePost(uid);
  }

  addToBookmark(uid) {
    //this.timelineProvider.addToBookmark(uid);
  }

  removeFromBookmark(uid) {
    //this.timelineProvider.removeFromBookmark(uid);
  }

  addPost() {
    //this.modalCtrl.create('AddPostPage').present();
  }

  editPost(uid)
  {
    console.log("edit", uid);
  }


  deletePost(uid) {

    /*
    if(this.viewCtrl.name=='CollectionDetailPage') 
    {
      console.log("delete post from collection post/uid", uid);
      this.timelineProvider.removePostFromCollection(uid, TimelineComponent.CURRENT_COLLECTION);
      return;
    }

    this.timelineProvider.deletePost(uid);
    */
  }

  getDate(date) {
    if (date)
      return 1;//moment(new Date(date.seconds * 1000)).fromNow();
    return 0;//moment(new Date()).fromNow();
  }

  likeState(likes: any) {
    if (likes) {
      if (likes[this.account.uid])
        return true;
    }
    return false;
  }

  bookmarkState(bookmark: any) {
    if (bookmark) {
      if (bookmark[this.account.uid])
        return true;
    }
    return false;
  }


  commentState(comments: any) {
    if (comments) {
      //myArray.map(function(e) { return e.hello; }).indexOf('stevie');
      if (comments.map((e) => e.commentBy).indexOf(this.account.uid) !== -1)
        return true;
    }
    return false;
  }

  comment(uid) {
    console.log('PostId.Home', uid);
    //this.modalCtrl.create('CommentPostPage', { uid: uid, account: this.account }).present();
  }

  sharePost(post) {
    console.log('Sharing', post);
    /*
    this.modalCtrl.create('AddPostPage',
      {
        text: post.text,
        postOwner: post.user,
        postOwnerId: post.postBy,
        postId: post.uid,
        photoURL: post.photoURL || null,
        videoURL: post.videoURL || null
      }).present();
      */
  }


  callOnDemand(postOwner) {

    console.log('B1X calling', postOwner);
    //MessagesPage.DZWONIE_FROM_TIMELINE_ID = postOwner;

    
    //this.navCtrl.setRoot("MessagesPage");
  }
  //Current User Profile
  
  authorVeryfied(post)
  {
    //this.modalCtrl.create('RulesAboutVideoPage', { post: post, veryfied: true}).present();
  }

  authorUndefined(post)
  {
    //this.modalCtrl.create('RulesAboutVideoPage', { post: post, veryfied: false}).present();
  }
  playVideo(post) {

    console.log('playVideo', post.wwwURL);

    this.timeline[this.timeline.findIndex(x => x.uid == post.uid)].views += 1;    
    //this.addView(post.uid)
    
    
    let autorID = post.postBy;
    console.log('cheking author', autorID);

    //this.navCtrl.push('B1XplayerPage', { url:post.wwwURL, post: post, account: this.account, autorID: autorID});

    //autor.subscribe(fetchedAutor=>{

      //console.log('fetchedAutor', fetchedAutor);

      //this.navCtrl.push('ProfilePage', { userId: fetchedAutor.uid});

      //this.app.getRootNav().push('B1XplayerPage', { post: post, account: this.account, autor: fetchedAutor});

      //this.navCtrl.setRoot('B1XplayerPage');
      //this.navCtrl.push('B1XplayerPage', { post: post, account: this.account, autor: fetchedAutor});

      //this.modalCtrl.create('B1XplayerPage', { post: post, account: this.account, autor: fetchedAutor}).present();

    //})

    
  }

  showCalculations(ileB1x:number, jakaIni:string)
  {
    console.log("jakaIni showCalculations/ile/ I AM IN ", ileB1x, jakaIni);

    //this.modalCtrl.create('B1XcalculatorPage', { ileB1x: ileB1x, jakaIni:jakaIni}).present();
  }


  openProfile() {
    //this.modalCtrl.create('ProfilePage').present();

    //this.navCtrl.push('ProfilePage', { userId: this.account.uid});

  }

  //Other User Profile
  goToProfile(userId) {    
    //this.modalCtrl.create('ProfilePage', { userId: userId }).present();
    //this.navCtrl.push('ProfilePage', { userId: userId});
  }

  countEv(obj) {
    if (obj)
      return Object.keys(obj).length;
    return 0;
  }

  viewPost(post:Post) {
    /*this.modalCtrl.create('ViewPostPage', { postId: postId }).present();

    console.log("I AM IN ", this.viewCtrl.name)

    if(this.viewCtrl.name=='ViewPostPage') 
    {
      return;
    }

    this.navCtrl.push('ViewPostPage', { uid: post.uid });
    */
  }

  //Create text array
  createStrArray(text: String) {

    const purePost = text.split("-|-")[0];
    //console.log('purePost', purePost);

    let str = purePost;
    if (!str || str === '')
      return '';

    let res = str.split(/[ ]/);
    return res;
  }

  //Open Search
  searchByTag(text) {
    console.log('searchStr', text);

    /*
    this.timelineProvider.searchStr = text;
    const nav = this.app.getActiveNav();

    console.log('Active NAV', this.navCtrl.getActive().name);

    if (this.navCtrl.getActive().name == 'HotTimelinePage')
    {
      this.navCtrl.setRoot('SearchPage');

    }
    else
    {
      this.navCtrl.setRoot('SearchPage');
    }
    
    else if ((this.navCtrl.getActive().name !== 'HotTimelinePage') && (this.navCtrl.getActive().name !== 'SearchPage'))
      this.navCtrl.pop();

    if (this.navCtrl.getActive().name !== 'SearchPage')
      nav.parent.select(1);
      */
  }

  async openProfileByQuote(quote) {
    //let userId = await this.accountProvider.getUserIdByUsername(quote);
    console.log('quote', quote);
    //this.goToProfile(userId);

  }



  isURL(text) {
    var pattern = new RegExp('(?:(?:(?:ht|f)tp)s?://)?[\\w_-]+(?:\\.[\\w_-]+)+([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-])?'); // fragment locater
    if (!pattern.test(text)) {
      return false;
    } else {
      return true;
    }
  }

  openBrowser(url: string) {
    if (!url.includes('//'))
      url = 'http://' + url;
    (<any>window).open(url);
  }

  play(postId) {
    this.timeline[this.timeline.findIndex(x => x.uid == postId)].views += 1;    
  }

  
}
