import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController, IonItem, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription, timer } from 'rxjs';


@Component({
  selector: 'app-b1x-player',
  templateUrl: './b1x-player.page.html',
  styleUrls: ['./b1x-player.page.scss'],
})
export class B1xPlayerPage implements OnInit {

  @ViewChild('videoPlayer', { static: true }) videoPlayer: any;

  @ViewChild('content', { static: true }) content: any;

  B1X_INTERVAL = 5;
  isYT: boolean;
  wwwLink: string;
  price: number;
  cleanSupportURL:any;
  szer:string;
  wysoko:string;
  ifrejm:any;
  observableVar: Subscription;
  B1Xvideo_name:string = 'no-name'
  start_playing = true;
  
  constructor(public platform:Platform, navParams: NavParams, private modalController:ModalController, public sanitizer: DomSanitizer) { 
    this.isYT = navParams.get('isYT')
    this.wwwLink = navParams.get('wwwLink')
    this.price = navParams.get('price')
    
    this.initiatePlatformIfReady();
  }

  initiatePlatformIfReady() {
    this.platform.ready().then(() => {
      console.log('before subscribe');

      this.platform.resize.subscribe(() => {
        
        this.resizeAll();
      });
    });
  }

  dismissModal() {
    this.modalController.dismiss();
   }

  getInnerWidth(elem) {

    let last = parseFloat(window.getComputedStyle(elem).width);
    if(last>800) last=800;
    
    return last
  }

  ngAfterViewInit()
  {
     
     this.ifrejm = document.getElementById('ifrejm')
     //console.log("ngAfterViewInit w", this.ifrejm.width);
  }

  resizeAll()
  {
    console.log('resized', this.szer);
    this.ifrejm = document.getElementById('ifrejm');
    
    if(!this.ifrejm) 
    {
      this.wysoko = (this.getInnerWidth(window.document.body) * 0.5625) + "px"
      this.szer = this.getInnerWidth(window.document.body) + "px";
      console.log("resizeAll XXX", this.szer);
    }
    else
    {
      this.wysoko = (this.getInnerWidth(this.ifrejm) * 0.5625) + "px"
      this.szer = this.getInnerWidth(this.ifrejm) + "px";
      console.log("resizeAll ifrejm", this.szer);
    }
   
  }

  ngOnInit() {
    console.log("B1xPlayerPage", this.wwwLink);
    let pikseleH;
    this.resizeAll();
    if(this.isYT)
    {
      let ytLink = "https://www.youtube.com/embed/" + this.youtube_parser(this.wwwLink);
      this.cleanSupportURL = this.sanitizer.bypassSecurityTrustResourceUrl(ytLink);
    }
    
    this.observableVar = timer(700).subscribe(()=>{
      this.tick();
    });
  }

  tick()
  {
    this.observableVar.unsubscribe();
    this.observableVar = null;
    this.resizeAll();
  }

  youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }



  dismiss() {
    this.observableVar.unsubscribe();
    this.observableVar = null;
    this.modalController.dismiss({
      'action': 'empty'
    });
  }
}
